
body {
    margin: 0;
    padding: 0;
    background-color: #f0f8ff; 
    font-family: Arial, sans-serif;
  }
  
 
  .app-container {
    position: relative;
    height: 100vh; 
    display: flex;
    flex-direction: column;
  }

  
  
  .home:hover {
    background-color: #45a049; 
  }
  
 
  .center-container {
    flex-grow: 1; 
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  
  .connect {
    padding: 15px 30px;
    margin-top: 25%;
    margin-left: 40%;
    background-color: #007bff; 
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .connect:hover {
    background-color: #0056b3; 
  }

  .p{
    padding: 10px;
    margin-top: 10%;
    margin-left: 40%;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
  }
  .address{
    padding: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 15px;
  }

  .button {
    margin-left: 50%;
    margin-top: 0%;
  
  }

  .home, .dsm, .transfer, .deployed, .disconnect{
   margin: 10px;
  
   font-family: 'Courier New', Courier, monospace;

  }
  .home:hover, .dsm:hover, .transfer:hover, .deployed:hover, .disconnect:hover{
    background-color: #45a049; 
  }

 .select{
   padding: 10px;
   font-family: 'Courier New', Courier, monospace;
   font-size: 20px;
 }
 .clear{
 margin: 10px;
   font-family: 'Courier New', Courier, monospace;
   
 }

 .select-network{
    padding: 2px;
    font-family: 'Courier New', Courier, monospace;
   
 }
 

  
.container {
  display: flex;
  flex-direction: column;
  gap: 20px; 
}


.content {
  display: flex;
  justify-content: space-between; 
  gap: 20px; 
}


.nfts {
  flex: 1; 
 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.transaction {
  flex: 1; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Courier New', Courier, monospace;
  
}


.token {
  font-family: 'Courier New', Courier, monospace;
  margin-left: 76%;
}